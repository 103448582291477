.statusWrapper {
  margin-bottom: 45px;
}

.statuses {
  display: flex;
  margin-bottom: 10px;
}

.statusTitle,
.status {
  font-weight: 500;
  font-size: 20px;
  line-height: 12px;
}

.statusTitle {
  color: #646464;
  padding-right: 10px;
  margin-right: 10px;
  position: relative;

  &:after {
    content: '';
    width: 1px;
    height: 17px;
    background: #646464;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.status {
  color: #49b096;
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 20px;
  color: #1e252b;
}