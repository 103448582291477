@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
@import './App';

html,
body,
button,
input {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 24px;
  background-color: #f9f9fd;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: rgba(30, 31, 31, 0.2);
}

*::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #f9f9fd;
}

input:-webkit-autofill{
  box-shadow:inset 0 0 0 1000px #fff;
}

button:hover {
  cursor: pointer;
  transition: .5s all linear;
}