@import 'src/App';

.newTasks {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.block {
  height: 200px;
}