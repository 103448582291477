.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.modalText {
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #1e252b;
}

.success {
  color: #49b096;
}

.cancel {
  color: #fb2626;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.button {
  width: 47%;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  padding: 16px;
  flex-grow: 1;
  background: #2b2b2b;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}