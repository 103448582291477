$loaderSize: 150px;

.loaderMat {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(73, 72, 72, .8);
  position: absolute;
  top: 0;
  left: 0;
}
.loader {
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #93a7a1;
  -webkit-animation: spin 2s linear infinite;
  //animation: spin 2s linear infinite;
  transform: translate(-50%, -50%);
  &:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #86b8ab;
    -webkit-animation: spin 3s linear infinite;
    //animation: spin 3s linear infinite;
  }
  &:after {
    content: "";
    position: absolute;
    top: 9px;
    left: 8px;
    right: 8px;
    bottom: 9px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #4C897A;
    -webkit-animation: spin 1.5s linear infinite;
    //animation: spin 1.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}