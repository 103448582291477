@import './src/App.scss';

.table {
  min-width: 621px;
  max-width: 1280px;
  border: 1px solid #000000;
}

.head,
.content {
  display: grid;
  grid-template-areas: 'empty code date info cost status';
  grid-template-columns: 50px 1.3fr 1.3fr 1fr 1fr 1.2fr;
}

.priority {
  border: 1px solid #ff0000;
}

.empty,
.code,
.date,
.info,
.cost {
  padding: 10px;
}

.empty,
.code,
.date,
.info,
.cost,
.status {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1e252b;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  justify-content: center;
  
  & > button {
    color: #0a7fe3;
    text-decoration: none;

    &:hover {
      color: lighten(#0a7fe3, 10%);
    }
  }
}

.head {
  .empty,
  .code,
  .date,
  .info,
  .cost,
  .status {
    justify-content: center;
    color: #1e252b;
    text-align: center;
    font-weight: 500;
    background: #dfdfdf;
  }

  .status {
    padding: 5px;
  }
}

.buttonShowInfo {
  font-size: 20px;
  line-height: 24px;
  background: none;
  border: none;
}

.buttonStatus {
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  background: $buttonColor;
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  position: relative;

  &:hover {
    background: lighten($buttonColor, 10%);
  }
}

.noTasks {
  min-height: 50px;
  padding: 10px;
  font-size: 24px;
  text-align: center;
  position: relative;
}

.modalWrapper {
  width: 100vw;
  height: calc(100% - #{$headerHeight});
  position: fixed;
  top: $headerHeight;
  left: 0;
  background: rgba(98, 98, 98, 0.5);
  z-index: 100;
}

.priority {
  border: 1px solid #ff0000;
}

.arrowTop {
  width: 16px;
  height: 16px;
}