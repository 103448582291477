@import 'src/App';

.taskCard {
  width: 500px;
  background: #f9f9f9;
  border: 0.3px solid #76838f;
  border-radius: 10px;
  padding: 20px;
}

.priority {
  border: 1px solid #ff0000;
}

.title {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #000000;
  margin: 0 0 20px;
}

.taskInfo {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 30px;
}

.info {
  display: flex;
  justify-content: space-between;
}

.tableHead,
.tableValue {
  width: 49%;
  font-size: 20px;
  line-height: 15px;
  color: #1e252b;
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.tableHead {
  background: #dfdfdf;
  font-weight: 500;
}

.tableValue {
  background: #fff;
  font-weight: 400;
}

.buttonView {
  font-size: 20px;
  color: #0a7fe3;
  border: none;
  background: none;
  padding: 0;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.buttonEnd,
.buttonCancel {
  font-size: 24px;
  border: none;
  background: none;
  padding: 20px;
}

.buttonEnd {
  font-weight: 600;
  color: #fff;
  background: #535353;
  border: 0.1px solid #76838f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
}

.buttonCancel {
  text-decoration: underline;
  align-self: center;
}

.modalWrapper {
  width: 100vw;
  height: calc(100% - #{$headerHeight});
  position: fixed;
  top: $headerHeight;
  left: 0;
  background: rgba(98, 98, 98, 0.5);
}