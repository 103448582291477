.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(98, 98, 98, 0.5);
}

.contentWrap {
  width: 100%;
  height: 100%;
  border: transparent;
  border-radius: 10px;
  background: #fff;
  padding: 50px 45px;
  overflow-y: auto;
}

.btnClose {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #1E252B;
  margin-bottom: 30px;
}

.management {
  display: flex;
  margin-bottom: 30px;

  button {
    border: 1px solid #81BAAC;
    padding: 10px 25px;
    color: #555555;
    font-weight: 500;
    font-size: 22px;
  }

  button:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  button:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  button.active {
    background: #81BAAC;
    color: #3A3A3A;
    font-weight: 600;
  }
}

.head {
  grid-area: head;
  display: grid;
  grid-template-columns: 2fr 4fr 3fr 2fr;
}

.headCell {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.headCell {
  background: #a8d3c9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

  &:first-child {
    border-radius: 7px 0 0 0;
  }

  &:last-child {
    border-radius: 0 7px 0 0;
  }
}

.content {
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}

.footer {
  position: sticky;
  bottom: -50px;
  display: grid;
  grid-template-columns: 2fr 4fr 3fr 2fr;
}

.cell {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 20px;
  padding: 7px 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.order {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 20px;
  padding: 7px 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.goods {
  display: flex;
  flex-direction: column;
  grid-column-start: 3;
  grid-column-end: 7;
}

.endDatetime {
  grid-column-start: 7;
  grid-column-end: 10;
}

.sumTask {
  grid-column-start: 10;
  grid-column-end: 12;
}

.sumTitle {
  border-radius: 0 0 0 7px;
  grid-column-start: 1;
  grid-column-end: 4;
  background: #fff;
}

.sum {
  grid-column-start: 4;
  grid-column-end: 4;
  border-radius: 0 0 7px 0;
  background: #a8d3c9;
}

.emptyData {
  grid-column-start: 1;
  grid-column-end: 12;
}