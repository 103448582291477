.modal {
  width: 80%;
  min-width: 420px;
  max-width: 620px;
  max-height: 90%;
  background: #f9f9f9;
  border: 0.3px solid #76838f;
  border-radius: 10px;
  padding: 24px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
}

.orderTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #1e252b;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.terms {
  margin-bottom: 38px;
  padding-left: 30px;
  padding-right: 30px;
}

.titleBlockName {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1e252b;
  margin-bottom: 18px;
}

.titleBlockNameBlack {
  font-weight: 900;
}

.dateItem {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 10px;

  &:not(:last-child) {
    margin-bottom: 19px;
  }
}

.dateHead,
.dateValue {
  font-size: 18px;
  line-height: 22px;
  color: #1e252b;
}

.dateHead {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.dateValue {
  font-weight: 400;
  padding-bottom: 9px;
  border-bottom: 1px solid #4c897a;
}

.products {
  padding-left: 30px;
  padding-right: 30px;

  &:not(:last-child) {
    padding-bottom: 76px;
    margin-bottom: 76px;
    border-bottom: 12px solid #4c897a;
  }
}

.info {
  margin-bottom: 39px;
}

.tableInfo {
  display: grid;
  grid-gap: 10px;
}

.infoHead,
.infoContent {
  display: grid;
  grid-template-columns: 50px 1fr 110px;
  grid-template-rows: 40px;
  grid-gap: 10px;
}

.number,
.name,
.count,
.equipmentMaterial,
.equipmentName,
.equipmentProvider,
.legsMoldingMaterial,
.legsMoldingName,
.tk1Head,
.tk2Head,
.tk1Value,
.tk2Value,
.note,
.schemeValue {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 7px 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1e252b;
  border: 1px solid #4c897a;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.infoHead {
  .number,
  .name,
  .count {
    font-weight: 500;
    background: #a8d3c9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border: none;
  }
}

.equipment {
  margin-bottom: 35px;
}

.tableEquipment {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 31px;
}

.equipmentHead,
.equipmentContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.equipmentHead {
  .equipmentMaterial,
  .equipmentName,
  .equipmentProvider {
    font-weight: 500;
    background: #a8d3c9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border: none;
  }
}

.legsMoldingTable {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 35px;
}

.legsMoldingHead,
.legsMoldingContent {
  display: grid;
  grid-template-columns: 170px 1fr;
  grid-gap: 10px;
}

.legsMoldingHead {
  .legsMoldingMaterial,
  .legsMoldingName {
    font-weight: 500;
    background: #a8d3c9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border: none;
  }
}

.noteTable {
  display: grid;
  grid-template-areas:
          'tk1Head tk1Value'
          'tk2Head tk2Value'
          'note note';
  grid-template-columns: 113px 1fr;
  grid-gap: 10px;
  margin-bottom: 45px;
}

.tk1Head {
  grid-area: tk1Head;
  background: #a8d3c9;
  border: none;
}

.tk2Head {
  grid-area: tk2Head;
  background: #a8d3c9;
  border: none;
}

.tk1Value {
  grid-area: tk1Value;
}

.tk2Value {
  grid-area: tk2Value;
}

.note {
  min-height: 52px;
  display: unset;
  text-align: left;
  grid-area: note;
  margin-top: 13px;
}

.scheme {
  min-height: 34px;
  display: grid;
  grid-template-columns: 200px 82px;
  grid-gap: 10px;
  align-items: center;
}

.schemeTitle {
  margin-bottom: 0;
}

.schemeValue {
  padding: 7px;
  height: 100%;
}