// убираем стрелки в инпуте типа number
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}
//------------------------------------------------
.loginPage {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBlock {
  min-width: 500px;
  min-height: 236px;
  padding: 40px 50px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: url("../../assets/images/logo.svg") 21px 20px no-repeat;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 22px;
  color: #1E252B;
  text-align: center;
  margin: 0 0 25px;
}

.form {
  display: flex;
  flex-direction: column;
}

.fieldsWrapper {
  position: relative;
}

.idUserInput {
  width: 100%;
  padding: 18px 22px;
  font-size: 28px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 45px;
  border: none;

  &::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 17px;
    color: rgba(30, 37, 43, 0.53);
  }
}

.error {
  color: red;
  box-shadow: 0 0 6px rgba(255, 0, 0, 0.8);
}

.errorPrompt {
  font-size: 20px;
  color: red;
  position: absolute;
  bottom: 14px;
  left: 0;
}

.buttonEnter {
  padding: 22px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  border-radius: 10px;
  background: #737a79;
  border: none;

  &:hover {
    cursor: pointer;
  }
}
