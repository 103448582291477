.button {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  padding: 20px;
  background: #535353;
  border: 0.1px solid #76838f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  &:disabled:hover {
    cursor: default;
  }
}