@import "./src/App";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  padding: 20px 80px;
  display: grid;
  grid-template-areas: 'logo counter statistic info';
  grid-template-columns: 1fr 2fr 2fr 2fr;
  grid-gap: 30px;
  align-items: center;
  background: #fff;
  border: 0.3px solid #76838f;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  z-index: 100;
}

.logo {
  grid-area: logo;
  width: 80px;
  height: 60px;

  & > img {
    width: 100%;
  }
}

.counter {
  grid-area: counter;
  font-size: 28px;
}

.currency {
  font-weight: 400;
  color: #37474f;
}

.counterWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.counterText {
  font-weight: 400;
  font-size: 15px;
  line-height: 12px;
  color: #37474f;
}

.accountInfo {
  grid-area: info;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.userName {
  max-width: 220px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #37474f;
  margin-right: 16px;
  text-align: center;
}

.exit {
  font-weight: 600;
  font-size: 20px;
  color: #37474f;
  padding-left: 30px;
  background: url("../../assets/images/exit.svg") left no-repeat;
  background-size: auto 100%;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.btnStatistic {
  display: flex;
  gap: 10px;
  align-items: center;
  border: transparent;
  background: #81BAAC;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 18px;
}

.statisticIcon {
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
}